import { Divider, Flex, Text, Tooltip, useColorMode } from '@chakra-ui/react';
import { SalesWarning, profitTextColor } from './shared';
import { useTranslation } from 'react-i18next';
import { SalesCurrency } from '@texas/api/endpoints/salesCurrenciesApi';
import { Icons } from '@texas/components/shared/Icons';

interface SalesCurrencyTabProps {
  salesCurrency: SalesCurrency;
  warnings?: SalesWarning[];
}

export function SalesCurrencyTab(props: SalesCurrencyTabProps) {
  if (props.warnings) {
    return (
      <Tooltip
        backgroundColor="texas.bg.800"
        border="texas.bg.darkBlue"
        _light={{ backgroundColor: 'gray.10', border: 'gray.300' }}
        label={<SaleWarningsComponent warnings={props.warnings} />}
      >
        <Flex>
          <InnerTab salesCurrency={props.salesCurrency} />
        </Flex>
      </Tooltip>
    );
  }
  return (
    <Flex>
      <InnerTab salesCurrency={props.salesCurrency} />
    </Flex>
  );
}

function InnerTab({ salesCurrency }: { salesCurrency: SalesCurrency }) {
  return (
    <>
      {salesCurrency.isMain && <Icons.Flag pr={1} boxSize={5} />}
      <Text>
        {salesCurrency.currencyCode} ({salesCurrency.fixedExchangeRate})
      </Text>
    </>
  );
}

function SaleWarningsComponent({ warnings }: { warnings: SalesWarning[] }) {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();

  return (
    <Flex
      direction="column"
      gap={1}
      alignItems="center"
      fontSize={12}
      fontWeight="bold"
    >
      <Text variant="sub">{t('variant.sales.profitWarnings')}</Text>
      <Divider />
      {warnings.map((w) => {
        return (
          <Text
            key={w.field}
            color={profitTextColor(w.type, colorMode === 'dark')}
          >
            {w.field}
          </Text>
        );
      })}
    </Flex>
  );
}
